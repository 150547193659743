<template>
  <div class="home">
    <div >
      <div
          class="main-header-box"
          :style="{
          height: headerHeight,

          'background-image':
            `linear-gradient(rgb(18, 20, 46,0.6), transparent), url(` +
            getImg(currentVideo.videoInfo.bigPoster, '1600', '846', true) +
            ')',
        }"
          v-loading="titleLoading"
          element-loading-background="rgb(19, 21, 48)"
      >
        <!-- linear-gradient(
          rgba(0,0,0,0.2) 80%,
          rgba(0,0,0,0.5) 20%,
          #12142e 0%
        ), -->
        <div class="header-search">
          <search-bar />
        </div>
        <div class="header-video" v-show="!titleLoading">
          <div class="video-left">
            <div class="title">{{ currentVideo.slogan }}</div>
            <div class="detail">
              <div class="detail-thumb" @click="goDetail(currentVideo.videoId)">
  <!--              <img :key="currentVideo.videoId" :src="getImg(currentVideo.videoInfo.poster, '80', '115')" alt="" />-->
                <a-img :key="currentVideo.videoId" :array="[currentVideo.videoInfo.poster, '80', '115']"></a-img>
              </div>
              <div class="detail-title">
                <div class="text" @click="goDetail(currentVideo.videoId)">
                  {{ currentVideo.videoInfo.title }}
                </div>
                <div class="rating">
                  <span class="rating-1">{{ Number(currentVideo.videoInfo.rating).toFixed(1) }}</span>
                  <span class="rating-2"> /{{ currentVideo.videoInfo.ratingFull }}</span>
                </div>
              </div>
            </div>
            <div class="detail-tags">
              <div class="detail-tag" v-for="item of currentVideo.videoInfo.tags" :key="item.id">
                {{ item.tagName }}
              </div>
            </div>
          </div>

          <div class="video-right">
            <div class="video-right-title">精选推荐</div>
            <div class="rec-video-wrapper" v-if="recommendList.length">
              <div
                  class="rec-video-item"
                  v-for="(item, index) in recommendList"
                  :key="index"
                  @mouseover="mouseOver(index)"
                  @mouseleave="mouseLeave(index)"
              >
                <div class="video-item-left">
                  <div class="rec-video-item-img">
  <!--                  <img-->
  <!--                      :key="index"-->
  <!--                      @click="goDetail(item.videoId)"-->
  <!--                      :src="getImg(item.videoInfo.poster, '60', '86')"-->
  <!--                      alt=""-->
  <!--                  />-->
                    <a-img :key="index" @click="goDetail(item.videoId)" :array="[item.videoInfo.poster, '60', '86']"></a-img>

                  </div>

                  <div class="rec-video-item-title">
                    <div class="title-text" @click="goDetail(item.videoId)">
                      {{ item.videoInfo.title }}
                    </div>
                    <div class="title-text2">
                      <span v-for="(tag, index) in item.videoInfo.tags" :key="index">
                        <span v-show="index != 0">·</span>
                        <span class="title-text2-name">{{ tag.tagName }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="video-item-right">
                  <span class="video-item-right-rating">{{ item.videoInfo.rating.toFixed(1) }}</span>
                  <img @click="goDetail(item.videoId)" :src="item.playImg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="detail-rec" v-show="!titleLoading">
          <img :src="recIcon" alt="" />
          <span>为你推荐</span>
        </div>
        <div class="detail-block" @click="blockShow = true">
          {{ block.content }}
        </div>
        <div v-show="!titleLoading" class="play" @click="goDetail(currentVideo.videoInfo.videoId)">
          <img :src="bigPlayImg" alt="" />
        </div>
        <div class="detail-reload" @click="initRec()" v-show="!titleLoading">
          <img :src="reloadIcon" alt="" />
        </div>
      </div>

      <div class="rec-list" v-loading="listLoading" element-loading-background="rgb(19, 21, 48)">
        <div class="rec-item" v-for="(item, index) in moiveList" :key="index">
          <div class="rec-item-img" @click="goDetail(item.videoId)">
            <!-- <img :src="getImg(item.poster, '272', '392')" alt="" /> -->
            <lazy-pic :array="[item.poster, '272', '392']" type="poster" />
          </div>
          <div class="rec-item-title" @click="goDetail(item.videoId)">
            {{ item.title }}
          </div>
          <div class="rec-item-title2">
            <span v-for="(tag, index) in item.tags.slice(0, 3)" :key="index">
              <span v-show="index != 0">·</span>
              <span class="title-text2-name">{{ tag.tagName }}</span>
            </span>
          </div>
          <div class="rec-item-rating">
            {{ item.rating.toFixed(1) }}
          </div>
        </div>
      </div>

      <div class="bodymovinanim" v-show="loadingShow">
        <div>
          <img :src="loadingIcon" alt="" />
        </div>
      </div>

      <!--
      <page-select
        v-show="!listLoading && moiveList.length"
        :pagination="pagination"
        @pageChange="pageChange"
      ></page-select> -->

      <el-dialog
          title="公告"
          :visible.sync="blockShow"
          width="500px"
          :append-to-body="true"
          :before-close="
          () => {
            blockShow = false
          }
        "
      >
        <div class="block-dialog">
          <div class="bloack-dialog-content">
            {{ block.content }}
          </div>
          <div class="bloack-dialog-time">
            {{ block.createdAt }}
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import pageSelect from "./page-select"
import searchBar from "./search-bar"
import aImg from "./img"
var timer
let isLoad = false
export default {
  name: "Home",

  data() {
    return {
      backgroundImage: require("@assets/index.png"),
      avatarImage: require("@assets/avatar.png"),
      recIcon: require("@assets/home/rec.png"),
      recImage: require("@assets/thumb.png"),
      playImg: require("@assets/play_small_checked.png"),
      bigPlayImg: require("@assets/play_big.png"),
      reloadIcon: require("@assets/home/reload.png"),
      loadingIcon: require("@assets/loading.gif"),
      headerHeight: 0,
      searchWidth: 0,

      searchVal: "",
      searchPlaceHolder: "复仇者联盟",
      //  bannder
      currentVideo: {
        videoInfo: {
          bigPoster: "",
        }
      },
      // currentVideo: {
      //   slogan: "宫崎骏赋予它灵魂, 并深深打动每一个人",
      //   videoInfo: {
      //     bigPoster: "",
      //     poster: "",
      //     title: "千与千寻",
      //     rating: 9.5,
      //     ratingFull: 10,
      //     thumb: require("@assets/thumb.png"),
      //     tags: [
      //       {
      //         tagId: 1,
      //         tagName: "2020",
      //       },
      //       {
      //         tagId: 2,
      //         tagName: "日本",
      //       },
      //       {
      //         tagId: 3,
      //         tagName: "动画",
      //       },
      //     ],
      //   },

      // 右侧推荐视频
      recommendList: [],

      moiveList: [],
      bannerList: [],
      currentVideoIndex: 0,
      timer: null,

      pagination: {
        currentPage: 1,
        pageSize: 0,
        numFound: 0,
        totalPage: 0,
      },
      titleLoading: true,
      listLoading: true,
      block: {
        content: "",
        createdAt: "",
      },
      blockShow: false,
      loadingShow: false,
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    pageChange(e) {
      this.pagination.currentPage = e
      this.initRec()
      console.log(e)
    },
    async init() {
      await this.initBanner()
      await this.initRec()
      await this.getBlock()
    },
    async initBanner() {
      this.titleLoading = true
      const home = await this.api.getHome()
      this.recommendList = home.data.banner.list.map((i) => {
        i.playImg = require("@assets/play_small_checked.png")
        return i
      })
      this.setBanner(0)
      this.titleLoading = false
    },
    async initRec(isLoading) {
      console.log("跑进来了")
      if (!isLoading) {
        this.listLoading = true
      } else {
        isLoad = true
      }

      // let httpObj = { currentPage: this.pagination.currentPage }
      const rec = await this.api.getRecommond({
        pageSize: 15,
        currentPage: 1,
      })

      this.moiveList = [...rec.data.list, ...this.moiveList]
      this.loadingShow = false
      if (!isLoading) {
        this.listLoading = false
      }
      setTimeout(() => {
        isLoad = false
      }, 500)
    },
    setBanner(index) {
      console.log(index)
      this.currentVideo = this.recommendList[index]
    },
    getStyle() {
      this.headerHeight = (document.body.offsetWidth - 320) * 0.54 + "px"
      this.searchWidth = (document.body.offsetWidth - 320) * 0.68 + "px"
    },
    mouseOver(index) {
      this.recommendList[index].playImg = require("@assets/play_small.png")
      this.setBanner(index)
    },
    mouseLeave(index) {
      this.recommendList[index].playImg = require("@assets/play_small_checked.png")
    },
    async getBlock() {
      const result = await this.api.getHomeblock()
      this.block = result.data
      console.log(result)
    },

    getConfig() {
      //not build with site config
    },
    getScroll() {
      let _this = this
      document.querySelector(".el-main").addEventListener("scroll", function(e) {
        // console.log(e)
        _this.loadingShow = true
        const { scrollHeight, scrollTop, offsetHeight } = e.target
        console.log(parseInt(scrollTop + offsetHeight), scrollHeight)
        console.log("相减后的绝对值", Math.abs(scrollHeight - parseInt(scrollTop + offsetHeight)))
        if (Math.abs(scrollHeight - parseInt(scrollTop + offsetHeight)) < 5) {
          console.log("到底了")

          setTimeout(() => {
            if (!isLoad) _this.initRec(true)
          }, 500)
        }
      })
    },
  },
  mounted() {
    this.init()
    this.getStyle()
    this.getConfig()
    this.getScroll()
  },
  components: {
    searchBar,
    pageSelect,
    aImg
  },
}
</script>

<style lang="scss">
.home {
  background: rgb(18, 20, 46);
  width: 100%;
  height: 100%;
  // overflow-y: auto;
  // overflow-x: hidden;
  .main-header-box {
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    opacity: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .header-search {
      padding-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      width: calc(100% - 100px);
      top: 0;
      left: 0;
      padding-left: 50px;
      padding-right: 50px;
      .search-input {
        input {
          border: 1px solid rgba(255, 255, 255, 0.1);
          background: rgba(255, 255, 255, 0.1);
          height: 70px;
        }
      }
      .search-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 12px;
        .right-subscribe {
          width: 120px;
          height: 50px;
          border: 2px solid rgba(255, 255, 255, 0.5019607843137255);
          opacity: 1;
          border-radius: 5px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .right-avatar {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          overflow: hidden;
          margin-left: 40px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .header-video {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 50px;
      width: 100%;
      z-index: 6;
      margin-top: 80px;
      .video-left {
        // padding-top: calc(100% * 0.1);
        min-width: calc(100% * 0.25);
        max-width: 50%;
        .title {
          font-size: 3rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #ffffff;
          width: 55%;
          user-select: none;
        }
        .detail {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-top: calc(100% * 0.1);
          .detail-thumb {
            img {
              width: 90px;
              height: auto;
            }
            img:hover {
              cursor: pointer;
            }
          }
          .detail-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-left: 20px;
            .text {
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 500;
              line-height: 30px;
              color: #ffffff;
            }
            .text:hover {
              cursor: pointer;
              text-decoration: underline;
            }

            .rating {
              .rating-1 {
                font-size: 25px;
                font-family: DINPro;
                font-weight: bold;
                color: #ff3465;
              }
              .rating-2 {
                font-size: 18px;
                font-family: DINPro;
                font-weight: 500;
                color: #797a8e;
              }
            }
          }
        }
        .detail-tags {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 40px;
          .detail-tag {
            height: 49px;
            border: 1px solid rgba(255, 255, 255, 0.8);
            opacity: 1;
            border-radius: 25px;

            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            padding: 0px 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.8);
            margin-right: 10px;
          }
        }
      }
      .video-right {
        min-width: calc(100% * 0.25);
        max-width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .video-right-title {
          text-align: right;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 25px;
          color: #ffffff;
          width: 100%;
        }
        .rec-video-wrapper {
          width: 100%;
          margin-top: 20px;
          .rec-video-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            padding-right: 0;
            user-select: none;
            .video-item-left {
              display: flex;
              justify-content: center;
              align-items: center;
              .rec-video-item-img {
                width: 60px;
                overflow: hidden;
                border-radius: 10px;
                img {
                  width: 100%;
                  display: block;
                  height: auto;
                }
              }

              img:hover {
                cursor: pointer;
              }
              .rec-video-item-title {
                margin-left: 20px;
                .title-text {
                  font-size: 18px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  line-height: 25px;
                  color: rgba(255, 255, 255, 0.8);
                }
                .title-text:hover {
                  cursor: pointer;
                  text-decoration: underline;
                }
                .title-text2 {
                  margin-top: 10px;
                  span {
                    margin-right: 4px;

                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    line-height: 20px;
                    color: rgba(121, 122, 142, 1);
                  }
                  .title-text2-name:hover {
                    cursor: pointer;
                    text-decoration: underline;
                  }
                }
              }
            }
            .video-item-right {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-left: 54px;
              .video-item-right-rating {
                font-size: 25px;
                font-family: DINPro;
                font-weight: bold;
                line-height: 32px;
                color: rgba(255, 255, 255, 0.8);
                padding-right: 15px;
              }
              img {
                width: 40px;
                height: 40px;
                cursor: pointer;
                // margin-right: 20px;
              }
            }
          }
          .rec-video-item:hover {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            .video-item-left {
              .rec-video-item-title {
                .title-text {
                  color: white;
                }
                .title-text2 {
                  span {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .detail-rec {
      position: absolute;
      left: calc(1% + 50px);
      bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      z-index: 10;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      span {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 30px;
        color: #b6b7c3;
      }
    }
    .detail-block {
      z-index: 10;
      position: absolute;
      bottom: 50px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #ffffff;
      user-select: none;
    }
    .detail-block:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    .play {
      position: absolute;
      width: calc(100% * 0.2);
      bottom: 10%;
      right: 34%;
      z-index: 6;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .detail-reload {
      position: absolute;
      right: calc(1% + 50px);
      bottom: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 10;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .main-header-box:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, #12142e 3%, transparent);
    z-index: 5;
  }

  .rec-list {
    padding: 0 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 30px;
    .rec-item {
      width: 18%;
      margin-right: 1%;
      margin-left: 1%;
      margin-bottom: 40px;
      position: relative;
      .rec-item-img {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
      .rec-item-title {
        font-size: 19px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 26px;
        color: #b6b7c3;
        margin-top: 20px;
      }
      .rec-item-title:hover {
        text-decoration: underline;
        cursor: pointer;
      }
      .rec-item-title2 {
        margin-top: 10px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #797a8e;
        span {
          margin-right: 4px;
        }
      }
      .rec-item-title2 {
        .title-text2-name:hover {
          text-decoration: underline;
          cursor: default;
        }
      }
      .rec-item-rating {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #121330;
        opacity: 0.9;
        border-radius: 5px;
        font-size: 1.2vw;
        font-family: DINPro;
        font-weight: bold;
        color: #ff3465;
        width: calc(100% * 0.23);
        text-align: center;
        height: calc(100% * 0.08);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 0;
        user-select: none;
      }
    }
  }
}
.block-dialog {
  min-height: 100px;
  position: relative;
  .block-dialog-content {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
  }
  .block-dialog-time {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
@media screen and (max-width: 1680px) {
  .home {
    .rec-list {
      .rec-item {
        .rec-item-title {
          font-size: 16px;
          margin-top: 12px;
        }
      }
    }
    .main-header-box {
      .header-video {
        .video-left {
          .title {
            font-size: 24px;
          }
          .detail {
            padding-top: 20px;
          }
          .detail-tags {
            margin-top: 20px;
            .detail-tag {
              margin-top: 20px;
              height: 30px;
              font-size: 14px;
              padding: 0 15px;
            }
          }
        }
        .video-right {
          .video-right-title {
            margin-top: 0px;
          }
          .rec-video-wrapper {
            margin-top: 5px;
            .rec-video-item {
              padding: 4px 10px;
              .video-item-left {
                .rec-video-item-img {
                  width: 40px;
                }

                .rec-video-item-title {
                  .title-text {
                    font-size: 14px;
                  }
                  .title-text2 {
                    margin-top: 0;
                    span {
                      font-size: 12px;
                    }
                  }
                }
              }
              .video-item-right {
                .video-item-right-rating {
                }
              }
            }
          }
        }
      }
      .detail-rec {
        img {
          width: 25px;
          height: 25px;
        }
        span {
          font-size: 18px;
        }
      }
      .play {
        right: 44%;
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .home {
    .main-header-box {
      .header-video {
        .video-left {
          .title {
            font-size: 18px;
          }
          .detail {
            padding-top: 10px;
            .detail-thumb {
              img {
                width: 60px;
                cursor: pointer;
              }
            }
            .detail-title {
              .text {
                font-size: 16px;
              }
              .rating {
                .rating-1 {
                  font-size: 22px;
                }
                .rating-2 {
                  font-size: 16px;
                }
              }
            }
          }
          .detail-tags {
            margin-top: 10px;
            .detail-tag {
              padding: 0 10px;
              height: 28px;
              font-size: 14px;
            }
          }
        }
        .video-right {
          .video-right-title {
            margi-top: 50px;
          }
          .rec-video-wrapper {
            margin-top: 5px;
            .rec-video-item {
              padding: 5px;
              .video-item-left {
                img {
                  width: 30px;
                }
                .rec-video-item-title {
                  margin-left: 10px;
                  .title-text {
                    font-size: 16px;
                  }
                  .title-text2 {
                    span {
                    }
                  }
                }
              }
              .video-item-right {
                .video-item-right-rating {
                  font-size: 20px;
                }
                img {
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
        }
      }
      .detail-rec {
        span {
          font-size: 16px;
        }
      }
      .header-search {
        .search-input {
          input {
          }
        }
        .search-right {
          .right-subscribe {
          }
          .right-avatar {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

.bodymovinanim {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 110px;
  }
}
</style>
